<template>
  <div class="mt-2">
    <b-container class="mb-2">
      <b-row class="d-flex justify-content-between">
        <b-col
          :class="[hoveredCol1 ? 'bg-colorGreen ' : 'bg-colorGrey ']"
          @mouseover="hoveredCol1 = true"
          @mouseleave="hoveredCol1 = false"
          class="py-2 image-container-hover"
          style="border-radius: 20px"
        >
          <div class="text-center">
            <img
              class="base-img"
              src="@/assets/images/icons/contactUs/location.svg"
              img-fluid
            />

            <img
              class="hover-img"
              src="@/assets/images/icons/contactUs/location white.svg"
              img-fluid
            />
          </div>

          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              AWT Plaza 1st Floor, AWT Plara, Mall Road, Sadder, Rawalpindi,
              Pakistan
            </h6>
          </div>
        </b-col>
        <b-col
          :class="[hoveredCol2 ? 'bg-colorGreen' : 'bg-colorGrey']"
          @mouseover="hoveredCol2 = true"
          @mouseleave="hoveredCol2 = false"
          md=""
          class="py-2 ml-0 mt-1 mt-md-0 ml-md-1 image-container-hover"
          style="border-radius: 20px"
        >
          <div class="text-center">
            <img
              src="@/assets/images/icons/contactUs/email.svg"
              img-fluid
              class="base-img"
            />
            <img
              src="@/assets/images/icons/contactUs/email white.svg"
              img-fluid
              class="hover-img"
            />
          </div>
          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              info@medasktours.com
            </h6>
          </div>
        </b-col>
        <b-col
          :class="[hoveredCol3 ? 'bg-colorGreen' : 'bg-colorGrey']"
          @mouseover="hoveredCol3 = true"
          @mouseleave="hoveredCol3 = false"
          md=""
          class="py-2 ml-0 mt-1 mt-md-0 ml-md-1 image-container-hover"
          style="border-radius: 20px"
        >
          <div class="text-center">
            <img
              src="@/assets/images/icons/contactUs/phone.svg"
              img-fluid
              class="base-img"
            />
            <img
              src="@/assets/images/icons/contactUs/phone white.svg"
              img-fluid
              class="hover-img"
            />
          </div>
          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              +92 319 6048487
            </h6>
          </div>
        </b-col>
        <!-- <b-col
          md=""
          :class="[hoveredCol4 ? 'bg-colorGreen' : 'bg-colorGrey']"
          @mouseover="hoveredCol4 = true"
          @mouseleave="hoveredCol4 = false"
          class="py-2 ml-0 mt-1 mt-md-0 ml-md-1 image-container-hover text-center"
          style="border-radius: 20px"
        >
          <img
            src="@/assets/images/icons/contactUs/time.svg"
            img-fluid
            class="base-img"
          />
          <img
            src="@/assets/images/icons/contactUs/time white.svg"
            img-fluid
            class="hover-img"
          />

          <div class="pt-2 px-3">
            <h6 class="font-weight-bolder text-colorText text-center">
              09:00 AM to 18:00 PM <br />
              Sunday to Thursday
            </h6>
          </div>
        </b-col> -->
      </b-row>
    </b-container>
    <b-container class="mb-2">
      <h3 class="font-weight-bolder text-colorBlue text-center py-2">
        Fill In Your Information & We Will Be In Touch As Soon As We Can
      </h3>
      <b-row class="d-flex justify-content-start align-items-center p-0 m-0">
        <b-col lg="5" md="7" sm="12" class="">
          <img
            src="@/assets/images/contactUs/4.jpeg"
            class="mb-2 mb-md-0 img-fluid rounded h-100"
          />
        </b-col>
        <b-col md="5" lg="7" sm="12" class="m-0">
          <b-card class="m-0 p-0 h-100">
            <validation-observer ref="contactUsPersonalCreateFormValidation">
              <b-form @submit.prevent>
                <b-row>
                  <b-col md="12">
                    <b-form-group label-for="name">
                      <template #label>
                        Name <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        :rules="{ required }"
                      >
                        <b-form-input
                          id="name"
                          v-model="name"
                          v-restrict="regex"
                          :state="errors.length > 0 ? false : null"
                          name="name"
                          placeholder="Name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="mobilenumber">
                      <template #label>
                        Contact Number <span class="text-danger">*</span>
                      </template>

                      <VuePhoneNumberInput
                        v-model="mobileNo"
                        @update="onVuePhoneNumberUpdate"
                        :required="true"
                        color="#06bd46"
                        valid-color="#06bd46"
                        error-color="#ea5455"
                        :error="
                          mobileNoData !== null
                            ? !mobileNoData['isValid']
                            : false
                        "
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label-for="email">
                      <template #label>
                        Email <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="email"
                          v-model="email"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Email"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="">
                  <b-col md="12">
                    <b-form-group label-for="referenceNumber">
                      <template #label>
                        Reference Number <span class="text-danger">*</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Reference Number"
                        rules="integer"
                      >
                        <b-form-input
                          id="referenceNumber"
                          v-model="referenceNumber"
                          :state="errors.length > 0 ? false : null"
                          name="referenceNumber"
                          placeholder="Reference Number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group :label-for="message">
                      <template #label> Message </template>
                      <validation-provider
                        #default="{ errors }"
                        name="Messsage"
                        :rules="{ required }"
                      >
                        <b-form-textarea
                          id="message"
                          v-model="contactMessage"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Message"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <div class="text-center">
                  <b-button
                    type="submit"
                    variant="success"
                    class=""
                    @click="validateForm"
                  >
                    Submit
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <!-- <form-wizard
      color="#06bd46"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="steps-transparent mb-3"
      @on-complete="formSubmitted"
      :key="formWizardKey"
    >

      <tab-content
        title="Personel Details"
        icon="feather icon-user"
        :before-change="personalValidationForm"
      >
        <validation-observer ref="contactUsPersonalCreateFormValidation">
          <b-form @submit.prevent>
            <div>
           
              <b-row class="justify-content-start">
                <b-col md="6">
                  <b-form-group label-for="name">
                    <template #label>
                      Name <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      :rules="{ required }"
                    >
                      <b-form-input
                        id="name"
                        v-model="name"
                        v-restrict="regex"
                        :state="errors.length > 0 ? false : null"
                        name="name"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                
                  <b-form-group label-for="mobilenumber">
                    <template #label>
                      Contact Number <span class="text-danger">*</span>
                    </template>
           
                    <VuePhoneNumberInput
                      v-model="mobileNo"
                      @update="onVuePhoneNumberUpdate"
                      :required="true"
                      color="#06bd46"
                      valid-color="#06bd46"
                      error-color="#ea5455"
                      :error="
                        mobileNoData !== null ? !mobileNoData['isValid'] : false
                      "
                    />
             
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="justify-content-start">
                <b-col md="6">
                  <b-form-group label-for="email">
                    <template #label>
                      Email <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="email"
                        v-model="email"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Email"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-for="country">
                    <template #label>
                      Country <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      :rules="{ required }"
                    >
                      <v-select
                        :options="countries"
                        label="name"
                        @input="selectCountry"
                        v-model="selectedCountry"
                        placeholder="Select a country"
                      ></v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label-for="state">
                    <template #label>
                      State <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="State"
                      :rules="{ required }"
                    >
                      <v-select
                        :options="states"
                        label="name"
                        @input="selectState"
                        v-model="selectedState"
                        placeholder="Select a state"
                      ></v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label-for="city">
                    <template #label>
                      City <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      :rules="{ required }"
                    >
                      <v-select
                        :options="cities"
                        label="name"
                        @input="selectCity"
                        v-model="selectedCity"
                        placeholder="Select a city"
                      ></v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group label="Postal Code" label-for="postalcode">
                    <validation-provider
                      #default="{ errors }"
                      name="Postalcode"
                    >
                      <b-form-input
                        id="postalcode"
                        v-model="postalCode"
                        :state="errors.length > 0 ? false : null"
                        name="postalcode"
                        placeholder="Postal Code"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group label-for="required_treatment">
                    <template #label>
                      Required Treatment <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      name="treatment"
                      #default="{ errors }"
                      :rules="{ required }"
                    >
                      <v-select
                        label="name"
                        :state="errors.length > 0 ? false : null"
                        v-model="treatment"
                        placeholder="Select Treatment"
                        required
                        :options="treatments"
                        :reduce="(option) => option.id"
                        autoscroll
                        :clearable="true"
                      ></v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row> </b-row>
              <b-row>
                <b-col md="2">
                  <b-form-checkbox
                    id="carRental"
                    v-model="carRental"
                    name="carRental"
                    value="true"
                    unchecked-value="false"
                  >
                    Car rental
                  </b-form-checkbox>
                </b-col>
                <b-col md="2">
                  <b-form-checkbox
                    id="visaAssistance"
                    v-model="visaAssistance"
                    name="visaAssistance"
                    value="true"
                    unchecked-value="false"
                  >
                    Visa Assistance
                  </b-form-checkbox>
                </b-col>
                <b-col md="2">
                  <b-form-checkbox
                    id="hotel"
                    v-model="hotel"
                    name="hotel"
                    value="true"
                    unchecked-value="false"
                  >
                    Hotel
                  </b-form-checkbox>
                </b-col>
                <b-col md="2">
                  <b-form-checkbox
                    id="tourism"
                    v-model="tourism"
                    name="tourism"
                    value="true"
                    unchecked-value="false"
                  >
                    Tourism
                  </b-form-checkbox>
                </b-col>
                <b-col md="2">
                  <b-form-checkbox
                    id="insurance"
                    v-model="insurance"
                    name="insurance"
                    value="true"
                    unchecked-value="false"
                  >
                    Insurance
                  </b-form-checkbox>
                </b-col>
                <b-col md="2">
                  <b-form-checkbox
                    id="safeTravel"
                    v-model="safeTravel"
                    name="safeTravel"
                    value="true"
                    unchecked-value="false"
                  >
                    Safe Travel
                  </b-form-checkbox>
                </b-col>
                <b-col md="2">
                  <b-form-checkbox
                    id="interpreterService"
                    v-model="interpreterService"
                    name="interpreterService"
                    value="true"
                    unchecked-value="false"
                  >
                    Interpreter Service
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>
          </b-form>
        </validation-observer>
      </tab-content>

   
      <tab-content
        title="Medical Details"
        icon="feather icon-plus"
        :before-change="medicalValidationForm"
      >
        <validation-observer ref="contactUsMedicalCreateFormValidation">
          <b-row class="justify-content-start">
            <b-col md="6">
              <b-form-group label-for="gender">
                <template #label> Gender:</template>
                <validation-provider #default="{ errors }" name="Gender">
                  <b-form-radio-group
                    id="gender"
                    v-model="gender"
                    class="v-style-chooser"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-radio value="Male" class="ml-5">Male</b-form-radio>
                    <b-form-radio value="Female" class="ml-5"
                      >Female</b-form-radio
                    >
                  </b-form-radio-group>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Age" label-for="age">
                <validation-provider
                  #default="{ errors }"
                  name="Age"
                  :rules="{ regex: /^(?:[1-9]\d{0,2}|0)$/ }"
                >
                  <b-form-input
                    id="age"
                    v-model="age"
                    :state="errors.length > 0 ? false : null"
                    name="age"
                    placeholder="enter age"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col md="12">
              <b-form-group :label-for="message">
                <template #label>
                  <span class="font-weight-bolder text-colorText">Message</span>
                </template>
                <validation-provider #default="{ errors }" name="Messsage">
                  <b-form-textarea
                    id="message"
                    v-model="message"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Message"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <span class="font-weight-bolder text-colorBlack"
                >Upload Medical Reports</span
              >
              <b-form-group class="mb-0 mt-1">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                  :useCustomSlot="true"
                  @vdropzone-file-added="fileAdded"
                  class="bg-colorBlue"
                >
                  <div class="dropzone-custom-content">
                    <feather-icon
                      icon="UploadIcon"
                      class="mr-50 text-light"
                      size="60"
                    />
                    <h3
                      class="dropzone-custom-title text-light mt-1 font-weight-bolder"
                    >
                      Upload Multiple Files
                    </h3>
                    <div class="subtitle">
                      <p>Click browse thorough your machine</p>
                    </div>
                  </div>
                </vue-dropzone>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="px-2 py-2">
              <validation-provider
                #default="{ errors }"
                name="Agreement"
                :rules="{ required: { allowFalse: false } }"
              >
                <b-form-group>
                  <b-form-checkbox v-model="agreeToTerms">
                    I agree to MedAsk Tours Pakistan's Terms and Conditions, I
                    have read the Privacy Policy and I consent to the processing
                    of my provided details, including health data, by MedAsk
                    Tours Pakistan for the purpose of obtaining quotes.
                  </b-form-checkbox>
                  <b-form-invalid-feedback
                    :state="errors.length > 0 ? false : null"
                  >
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
     
    </form-wizard> -->
  </div>
</template>

<script>
import { getAllCountries } from "countries-and-timezones";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { mapActions } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Country, State, City } from "country-state-city";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    FormWizard,
    TabContent,
    ValidationProvider,
    ValidationObserver,
    VueSelectPaginated,
    ToastificationContent,
    FeatherIcon,
    vueDropzone: vue2Dropzone,
    Country,
    State,
    City,
    VuePhoneNumberInput,
  },
  mixins: [util],

  data() {
    return {
      procedures: null,
      name: "",
      email: "",
      mobileNo: "",
      country: "",
      referenceNumber: "",
      state: "",
      city: "",
      message: "",
      postalCode: "",
      treatment: null,
      treatments: [],
      carRental: false,
      visaAssistance: false,
      hotel: false,
      tourism: false,
      insurance: false,
      safeTravel: false,
      interpreterService: false,
      gender: "",
      age: "",
      contactMessage: "",
      files: [],
      mobileNoData: null,
      agreeToTerms: false,
      isFormValid: false,
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
      required: required,
      countries: [], // Initialize countries as an empty array
      states: [],
      cities: [],
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      formWizardKey: 0,
      regex: /^[a-zA-Z ]*$/,
      hoveredCol2: false,
      hoveredCol1: false,
      hoveredCol3: false,
      hoveredCol4: false,
    };
  },
  async mounted() {
    this.loadCountries();
    // this.fetchData();
  },

  computed: {
    treatmentNames() {
      return this.treatments.map((item) => item.name);
    },
  },
  methods: {
    ...mapActions({
      getprocedures: "appData/getProceduresPublicNav",
      createQuote: "appData/createQuote",
    }),
    loadCountries() {
      const countries = getAllCountries();
      this.countries = Object.values(countries).map((country) => ({
        name: country.name,
        code: country.id,
      }));
    },
    selectCountry(country) {
      this.selectedCountry = country;
      this.states = State.getStatesOfCountry(country.code);
      this.cities = [];
      this.selectedState = null;
      this.selectedCity = null;
    },
    selectState(state) {
      this.selectedState = state;
      this.cities = City.getCitiesOfState(
        this.selectedCountry.code,
        state.isoCode
      );

      this.selectedCity = null;
    },
    selectCity(city) {
      this.selectedCity = city;
    },
    onVuePhoneNumberUpdate(data) {
      this.mobileNoData = data;
    },
    async validateForm() {
      const success =
        await this.$refs.contactUsPersonalCreateFormValidation.validate();
      if (success && this.mobileNoData["isValid"]) {
        await this.submit();
      }
    },
    // async medicalValidationForm() {
    //   return this.$refs.contactUsMedicalCreateFormValidation.validate();
    // },
    onVuePhoneNumberUpdate(data) {
      this.mobileNoData = data;
    },
    async beforeChange() {
      const validationObserver = this.$refs.contactUsCreateFormValidation;
      const isValid = await validationObserver.validate();
      if (!isValid) {
        this.isFormValid = false;
        return false;
      } else {
        this.isFormValid = true;
        return true;
      }
    },

    // async fetchData() {
    //   const res_procedures = await this.getprocedures({});
    //   this.treatments = res_procedures.data.map((item) => {
    //     return {
    //       name: item.name,
    //       id: item.id,
    //     };
    //   });

    //   console.log(this.treatments, "ewqewewq");
    // },

    async submit() {
      // if (this.gender.text === "") {
      //   gender.text = "";
      // }
      let formattedMobileNo = this.mobileNoData["formatInternational"].replace(
        /[\s()+-]/g,
        ""
      );
      try {
        const bookingData = {
          full_name: this.name,
          mobile: formattedMobileNo,
          email: this.email,
          referral_code: this.referenceNumber,
          // country: this.selectedCountry.name,
          // state: this.selectedState.name,
          // city: this.selectedCity.name,
          // postal_code: this.postalCode,
          // procedure: this.treatment,
          // gender: this.gender ? this.gender : "",
          // age: this.age,
          message: this.contactMessage,
          // car_rental: this.carRental,
          // visa_assistance: this.visaAssistance,
          // hotel_booking: this.hotel,
          // tourism: this.tourism,
          // insurance: this.insurance,
          // safe_travel: this.safeTravel,
          // interpreter_service: this.interpreterService,
        };

        // Append booking data to FormData
        const formData = new FormData();
        for (const key in bookingData) {
          formData.append(key, bookingData[key]);
        }
        // Append files for booking reports
        // for (const file of this.files) {
        //   formData.append("images", file);
        // }

        // Send booking data and reports
        const response = await this.createQuote(formData);

        if (response.status === 200) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Form Submitted Successfully",
                variant: "success",
                icon: "BellIcon",
              },
            },
            {
              position: "top-right",
            }
          );
          this.reset();
          // this.formWizardKey += 1;
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Failed to Sent Quote",
                variant: "danger",
                icon: "BellIcon",
              },
            },
            {
              position: "top-right",
            }
          );
        }
      } catch (error) {
        this.displayError(error);
        console.log(error);
      }
    },
    reset() {
      // this.procedures = null;
      this.name = "";
      this.email = "";
      this.mobileNo = "";
      this.referenceNumber = "";
      // this.country = "";
      // this.state = "";
      // this.city = "";
      // this.postalCode = "";
      // this.treatment = null;
      // this.carRental = false;
      // this.visaAssistance = false;
      // this.hotel = false;
      // this.tourism = false;
      // this.insurance = false;
      // this.safeTravel = false;
      // this.interpreterService = false;
      // this.gender = "";
      // this.age = "";
      this.contactMessage = "";
      // this.files = [];
      // this.selectedCity = null;
      // this.selectedCountry = null;
      // this.selectedState = null;
    },
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/contactUs/2.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 380px;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

[dir] .col-form-label {
  padding-bottom: 0;
}
.hover-img {
  display: none;
}
.image-container-hover {
  display: block;
}
.image-container-hover:hover .hover-img {
  display: block;
  margin: 0 auto;
}

.image-container-hover:hover .base-img {
  display: none;
}
</style>
